export const splitTextSegments = (text) => {
    if (!text) return text;

    let segments = [];

    const delimiters = ' ,.，。!?;:、；：！？“”‘’（）《》〈〉【】『』「」…—'.split('');
    const candidates = delimiters
        .map((d) => ({ delimiter: d, index: text.indexOf(d) }))
        .filter((d) => d.index >= 0)
        .sort((a, b) => a.index < b.index);

    if (candidates.length === 0) {
        return [text];
    }

    const segment = text.substring(0, candidates[0].index + 1);
    segments.push(segment);

    const restText = text.substring(candidates[0].index + 1);
    if (restText.length > 0) {
        segments.push(...splitTextSegments(restText));
    }

    return segments;
};
