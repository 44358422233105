import { splitTextSegments } from './shared/literal.js';

(() => {
    const start = document.getElementById('start');
    const stop = document.getElementById('stop');
    const transcriptDiv = document.getElementById('transcript');
    const languageSelector = document.getElementById('language');
    const typewriterDuration = 1000;
    const requireBuffer = false;

    let recorder = null;
    let previousChunk = null;
    let lastChunkRequested = false;

    function renderPage() {
        start.disabled = recorder?.state === 'recording';
        stop.disabled = !start.disabled;
    }

    function sleep(interval) {
        return new Promise((res) => setTimeout(res, interval));
    }

    async function appendTranscript(text, position, conjunctionChar) {
        if (!text || text.length === 0) {
            return;
        }

        if (position && position >= 0) {
            transcriptDiv.innerText = transcriptDiv.innerText.substring(0, position);
            if (transcriptDiv.innerText.length > 0) {
                transcriptDiv.innerText += ' ';
            }
        }

        if (transcriptDiv.innerText.length > 0) {
            transcriptDiv.innerText += conjunctionChar ?? ' ';
        }

        console.log('splitting text', text);
        const chars = splitTextSegments(text);
        if (!chars || chars.length === 0) {
            return;
        }

        const interval = (typewriterDuration * 1.0) / chars.length;
        let current = chars.shift();
        while (current) {
            await sleep(interval);
            transcriptDiv.innerText += current;
            current = chars.shift();
        }

        transcriptDiv.scrollTop = transcriptDiv.scrollHeight;
    }

    async function uploadAudioFile(filename, blob, prompt, buffered = false) {
        const language = languageSelector.value;
        console.log('transcribing with', language);

        const formData = new FormData();
        formData.append('file', blob, filename);
        formData.append('prompt', prompt);

        if (language !== 'auto') {
            formData.append('language', language);
        }

        if (buffered) {
            formData.append('buffered', buffered);
        }

        const { data } = await axios.post(process.env.SERVER_URL + '/transcript/upload', formData, {
            headers: {
                'Content-Type': undefined, //'multipart/form-data',
                Accept: '*/*',
            },
        });

        appendTranscript(data.text, data.position, data.conjunctionChar);
    }

    start.addEventListener('click', async () => {
        if (transcriptDiv.innerText.length > 0) {
            transcriptDiv.innerText += '\n\n';
        }

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        recorder = new MediaRecorder(audioStream);
        let chunks = [];

        const filename = +new Date() + '.webm';
        const headDuration = 5;
        const chunkDuration = 5000;
        let headerChunk = null;
        let startTime = (+new Date()).toString();
        let stopHeadChunk = null,
            stopPartialChunk = null;

        let chunkId = 0;
        recorder.ondataavailable = async (e) => {
            if (headerChunk === null) {
                console.log('header chunk set');
                headerChunk = e.data;
                return;
            }

            chunkId++;

            let currentChunks = [headerChunk, e.data];
            if (requireBuffer && previousChunk) {
                currentChunks.splice(1, 0, previousChunk);
            }

            const audioBlob = new Blob(currentChunks, {
                type: 'audio/webm',
            });

            let prompt = transcriptDiv.innerText;
            if (prompt.length === 0) {
                prompt = 'Here are the key points for this case, be focus.';
            }

            uploadAudioFile(filename, audioBlob, prompt, requireBuffer);
            previousChunk = e.data;

            if (lastChunkRequested) {
                lastChunkRequested = false;
                previousChunk = null;
            }
        };

        recorder.onstart = (e) => {
            stopHeadChunk?.();
            stopPartialChunk?.();

            let headChunkTimer = setTimeout(() => {
                recorder.requestData();

                let partialChunkTimer = setInterval(() => recorder.requestData(), chunkDuration);
                stopPartialChunk = () => {
                    clearInterval?.(partialChunkTimer);
                    stopPartialChunk = null;
                };
            }, headDuration);

            stopHeadChunk = () => {
                clearTimeout(headChunkTimer);
                stopHeadChunk = null;
            };
        };

        recorder.onstop = (e) => {
            stopHeadChunk?.();
            stopPartialChunk?.();

            console.log('recording stopped');
        };

        recorder.start();
        renderPage();
    });

    stop.addEventListener('click', () => {
        lastChunkRequested = true;
        recorder.stream.getTracks().forEach((track) => track.stop());
        recorder.stop();
        renderPage();
    });

    renderPage();
})();
